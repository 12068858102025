
body,*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Catamaran, Regular";
}
html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}
h1,h2,h3,h4{
    font-weight: normal;
}
@font-face {
    font-family: "Julius Sans One, Regular";
    src: url(/src/Components/Fonts/JuliusSansOne-Regular.ttf);
}
@font-face {
    font-family: "Catamaran, Regular";
    src: url(/src/Components/Fonts/Catamaran-Regular.ttf);
}
@font-face {
    font-family: "Catamaran, SemiBold";
    src: url(/src/Components/Fonts/Catamaran-SemiBold.ttf);
}

@media (max-width: 1024px) {
    .ocultar{
        display: none;
    }
    .header nav{
        display: none;
    }
}
.ocultar-error{
    display: none;
}
.error{
    display: flex;
    color: red !important;
    font-size: 12px !important; 
}
/* .menu-open .header {

} */
.menu-open .header span {
    background-color: transparent !important;
}
.menu-open .header span::before{
    top: 0 !important;
    transform: rotate(-45deg);
}
.menu-open .header span::after{
    bottom: 0 !important;
    transform: rotate(45deg);
}
.menu-open .header nav{
    transform: translateY(118px);
}
.menu-open .header nav ul li a{
    font-size: 25px;
}
.menu-open .header nav ul li.redes{
    width: auto;
}

.btn_prototipos{
    display: none;
}
.whatsapp-movil{
    display: none;
}
@media (max-width: 1024px) {
    .btn_prototipos{
        background-color: #8A9E77;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }   
    .btn_prototipos a{
        color: white;
        padding: 10px;
        text-decoration: none;
        font-family: "Julius Sans One, Regular";
        font-size: 18px;
    }
    .whatsapp-movil {
        position: fixed;
        z-index: 9;
        top: 58%;
        right: 0;
        width: 70px;
        height: 60px;
        display: flex;
        background-color: #25D366;
        align-items: center;
        justify-content: center;
        
    }
    .whatsapp-movil a{
        padding-top: 5px;
    }
}
@media (max-width:550px) {
    .whatsapp-movil {
        width: 60px;
        height: 50px;
    }
    .whatsapp-movil img{
        width: 30px;
    }
}
.desliz-enter{
    transform: translateX(-200px);
}
.desliz-enter-active {
    transform: translateX(0);
    transition: transform 0.5s;
}
.desliz-enter-done  {
    transform: translateX(0);
}
.desliz-exit {
    transform: translateX(0);
}
.desliz-exit-active {
    transform: translateX(-200px);
    transition: transform 0.5s;
}
.desliz-exit-done {
    transform: translateX(-200px);
}
.show-enter{
    opacity: 0;
    transform: scale(0.9);
}
.show-enter-active {
    opacity: 0.5;
    transform: translateX(0);
    transition: opacity 0.1s, transform 0.5s;
}
.show-enter-done  {
    opacity: 1;
}
.show-exit {
    opacity: 1;
}
.show-exit-active {
    opacity: 0.5;
    transform: scale(0.9);
    transition: opacity 0.1s, transform 0.5s;
}
.show-exit-done {
    opacity: 0;
}
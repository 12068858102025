:root {
    --animate-delay: 0.5s;
  }


input[type=radio] {
    display: none;
  }
  
  .card {
    position: absolute;
    width: 40%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform .4s ease, opacity .5s ease;
    cursor: pointer;
  }
  
  .container {
    width: 100%;
    /* max-width: 800px; */
    max-height: 600px;
    height: 100%;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .cards {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card .amenidad {
    position: absolute;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .card .amenidad img{
    width: 70px;
    object-fit: contain;
  }
  .card .amenidad h2 {
    text-align: center;
    font-family: "Julius Sans One, Regular";
    color: white;
    font-size: 40px;
  }

  #song-1,#song-2,#song-3,#song-4,#song-5,#song-6,#song-7 {
    transform: translatex(0) scale(.8);
    opacity: 0;
    z-index: 0;
  }

  #item-1:checked ~ .cards #song-2{
    transform: translatex(-100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-1:checked ~ .cards #song-3{
    transform: translatex(100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-2:checked ~ .cards #song-1{
    transform: translatex(100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-2:checked ~ .cards #song-4{
    transform: translatex(-100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-3:checked ~ .cards #song-1{
    transform: translatex(-100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-3:checked ~ .cards #song-6{
    transform: translatex(100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-4:checked ~ .cards #song-2{
    transform: translatex(100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-4:checked ~ .cards #song-5{
    transform: translatex(-100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-5:checked ~ .cards #song-4{
    transform: translatex(100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-5:checked ~ .cards #song-7{
    transform: translatex(-100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-6:checked ~ .cards #song-3{
    transform: translatex(-100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-6:checked ~ .cards #song-7{
    transform: translatex(100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-7:checked ~ .cards #song-6{
    transform: translatex(-100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }
  #item-7:checked ~ .cards #song-5{
    transform: translatex(100%) scale(.8);
    opacity: 1;
    z-index: 0;
  }

  #item-1:checked ~ .cards #song-1, 
  #item-2:checked ~ .cards #song-2, 
  #item-3:checked ~ .cards #song-3,
  #item-4:checked ~ .cards #song-4,
  #item-5:checked ~ .cards #song-5,
  #item-6:checked ~ .cards #song-6,
  #item-7:checked ~ .cards #song-7 {
    transform: translatex(0) scale(1);
    opacity: 1;
    z-index: 1;
  }
  #item-1:checked ~ .cards #song-1, 
  #item-2:checked ~ .cards #song-2, 
  #item-3:checked ~ .cards #song-3,
  #item-4:checked ~ .cards #song-4,
  #item-5:checked ~ .cards #song-5,
  #item-6:checked ~ .cards #song-6,
  #item-7:checked ~ .cards #song-7 img{
    box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
  }
@media (max-width:1024px) {
  .card .amenidad h2 {
    font-size: 30px;
  }
  .card .amenidad {
    height: 150px;
  }
  .card .amenidad img{
    width: 50px;
  }
}
@media (max-width:550px) {
    .card{
        width: 60%;
    }    
    .card .amenidad {
      height: 120px;
    }
    .card .amenidad h2 {
      font-size: 22px;
    }
}